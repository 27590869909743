<template>
  <div
      class="user-wrapper"
      :class="{'user-wrapper--worker': user.role === 'worker'}"
  >
    <v-chip class="blue darken-1">
      {{ user.email }} <span v-if="role">, {{ role }}</span>
    </v-chip>

    <v-chip v-if="user.districts[0]" class="blue darken-1 ml-1">
      {{ user.districts[0].name }}
    </v-chip>

    <v-chip v-if="user.role === 'filial_manager' && user.region" class="blue darken-1 ml-1">
      {{ user.region.name }}
    </v-chip>

    <v-btn icon color="blue darken-1" @click="onLogoutClick">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {AUTH_LOGOUT} from "@/store/actions.type";

export default {
  methods: {
    onLogoutClick() {
      this.$router.push({path: '/'})
      this.$store
          .dispatch(AUTH_LOGOUT)
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),

    role() {
      switch (this.user.role) {
        case 'manager': {
          return 'Менеджер';
        }

        case 'company': {
          return 'Газпром';
        }
      }

      return false
    }
  }
}
</script>

<style scoped lang="scss">
.user-wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;

  &--worker {
    span {
      height: auto;
      white-space: normal;
    }
  }
}
</style>
